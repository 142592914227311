


































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import QuestObjective from "@/components/QuestObjective.vue";
import QuestLog from "@/components/QuestLog.vue";
import {IndexDBClient} from "@/api/indexeddb/IndexDBClient";
import Card from "@/components/Card.vue";
import Tabs from "@/components/Tabs.vue";
import Tab from "@/components/Tab.vue";
import {CardStatus} from "@/api/indexeddb/types";
import TimeLog from "@/components/TimeLog.vue";
import {multiselectMixin} from "vue-multiselect";

    @Component({
        name: "CardDetails",
        components: {QuestObjective, QuestLog, Card, Tabs, Tab, TimeLog}
    })
    export default class CardDetails extends Vue {

      @Prop({default: 0})
      protected cardId!: number;

      @Prop()
      protected card!: any;

      @Prop()
      protected objectiveList!: string[];

      @Prop()
      protected logList!: string[];

      protected statusTypes = [];

      @Prop()
      protected statusType?: any;

      protected monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];

      protected value: any;

      protected todoList: any = [];
      protected todoTitle = "";
      protected showTodo= true;

      protected historyList: any = [];
      protected timeLogList: any = [];

      public editTitle: any = "";
      public editDescription: any = "";

      private database = new IndexDBClient();

      protected time: string;
      protected logDescription: string;
      protected formattedTime: string;

      protected totalTime = "";

      constructor() {
          super();
          this.value = this.card.status;
          this.time = "";
          this.logDescription = "";
          this.formattedTime = "";
      }

      get stages() {
        this.database.cardStatus.then((status: never) => {
          this.statusTypes = status;
        })
        return this.statusTypes;
      }

      getIcon(icon){
        if(!icon)
        {
          return ['fa', 'exclamation-circle'];
        }
        return ['fa', icon];
      }

      get todos() {
        if(this.card.cardId != undefined) {
          this.database.activeChecklistsForCardId(this.card.cardId).then((checklist: any) => {
            this.todoList = checklist[0];
          });
        }
        return this.todoList;
      }

      set todos(value) {
        console.log(value);
      }

      addTodo() {
        if (this.todoTitle.trim() === "") {
          return;
        }

        if(!this.todos) {
          const newItems = {
            cardId: this.card.cardId,
            items: [{
              id: this.getGUID(0),
              title: this.todoTitle,
              isComplete: false
            }]
          };

          this.database.checklists = newItems;
          this.todoList = newItems;
        }
        else
        {
          const newItems = this.todoList;
          newItems.items.push({id: this.getGUID(this.card.cardId), title: this.todoTitle, isComplete: false});
          this.database.checklists = newItems;
          this.todoList = newItems;
        }
        this.todoTitle = "";
      }

      deleteTodo(checklistId) {
        this.todoList.items = this.todoList.items.filter((todo) => checklistId !== todo.id);
        this.database.checklists = this.todoList;
      }

      get history() {
        if(this.card.cardId != undefined) {
          this.database.getCardHistoryForCardId(this.card.cardId).then((history: any) => {
            this.historyList = history[0];
          });
        }
        return this.historyList;
      }

      get getHistoryLog(){
        this.historyList = this.history;
        if(!this.historyList || this.historyList.length == 0)
          return [];

        if(this.historyList.items && this.historyList.items.length > 1)
          return this.historyList.items.slice().reverse();

        return this.historyList.items;
      }

      get timeLogs() {
        if(this.card.cardId != undefined) {
          this.database.getCardLogsForCardId(this.card.cardId).then((logs: any) => {
            this.timeLogList = logs[0];
          });
        }
        return this.timeLogList;
      }

      get getTimeLogs(){
        this.timeLogList = this.timeLogs;
        if(!this.timeLogList || this.timeLogList.length == 0)
          return [];

        let time = 0;
        this.timeLogList.items.forEach( (event: any) => {
          time += Number(event.time);
        });

        this.totalTime = ""+time;

        if(this.timeLogList.items && this.timeLogList.items.length > 1)
          return this.timeLogList.items.slice().reverse();

        return this.timeLogList.items;
      }

      @Watch('value')
      saveStatus(newValue) {
        const cardToUpdate = this.card;
        cardToUpdate.status = newValue;
        cardToUpdate.dateLastActivity = new Date();
        this.database.cards = cardToUpdate;
        //this.value = newValue;

        this.database.getCardHistoryForCardId(this.card.cardId).then((history: any) => {
          const cardHistory = history[0];
          if(!cardHistory || cardHistory.items.length == 0){
            this.database.cardHistory = {
              cardId: cardToUpdate.cardId,
              items: [{
                id: this.getGUID(cardToUpdate.cardId),
                date: new Date(),
                card: cardToUpdate
              }]
            };
          }
          else {
            if(cardHistory.items[cardHistory.items.length-1].card.status.id !== cardToUpdate.status.id)
            {
              cardHistory.items.push({id: this.getGUID(cardToUpdate.cardId), date: new Date(), card: cardToUpdate});
              this.database.cardHistory = cardHistory;
            }
          }
        });
      }

      @Watch('editTitle')
      saveTitle(newValue) {
        this.card.title = newValue;
        this.database.cards = this.card;
      }

      @Watch('editDescription')
      saveDescription(newValue) {
        this.card.description = newValue;
        this.database.cards = this.card;
      }

      edit(event, msg){
        if(msg === "SaveTitle")
        {
          this.editTitle = event.target.innerText;
        }
        if(msg === "SaveDescription")
        {
          this.editDescription = event.target.innerText;
        }
      }

      getBackgroundColour(status: CardStatus) {
        switch (status.id) {
          case 1: return "bg-green-400";
          case 2: return "bg-red-400";
          case 3: return "bg-orange-400";
          case 4: return "bg-gray-800";
          default: return "bg-white";
        }
      }

      getMonthName(id: number): string {
        return this.monthNames[id];
      }

      getGUID = (function() {
        let cntr = 0;
        return function(userID) {
          const rand = Math.random().toString().replace(".", "");
          return userID + "_" + rand + "_" + cntr++;
        };
      })();

      get showTodoTab(): boolean {
        if(this.$route.matched[0].path === "")
          return true;
        return !this.$route.query.filter;
      }

      get showHistoryTab(): boolean {
        return this.$route.query['tab'] == "history";
      }

      get showTimeLogginTab(): boolean {
        return this.$route.query['tab'] == "time";
      }

      get statusValue() {
        const status = this.card.status;
        if(status != undefined && this.value.id !== this.card.status.id) {
          this.value = this.card.status;
        }
        return this.value;
      }

      set statusValue(value) {
        this.value = value;
      }

      completeTodo(id) {
        const updatedTodoList = this.todoList;

        updatedTodoList.items.forEach( (item: any) => {
          if(item.id == id){
            item.isComplete = !item.isComplete;
          }
        });

        this.database.checklists = updatedTodoList;
      }

      get cardTotalTime() {
        return this.totalTime;
      }

      set cardTotalTime(value) {
        this.totalTime = value;
      }

      get getFormattedTime() {
        const loggedTime = Number(this.totalTime);
        const hours = (loggedTime / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);

        //console.log(loggedTime + " minutes = " + rhours + " hour(s) and " + rminutes + " minute(s).");
        if(rhours == 0 && rminutes == 0){
          this.formattedTime = "";
        }
        else
          this.formattedTime = ""+rhours+"h"+(rminutes < 10? "0"+rminutes: rminutes);
        return this.formattedTime;
      }

      logTimeToCard() {
        const timeToLog = Number(this.time);
        const descriptionToLog = this.logDescription;
        this.totalTime = ""+(Number(this.totalTime) + timeToLog);

        this.database.getCardLogsForCardId(this.card.cardId).then((timeLogs: any) => {
          const cardLogs = timeLogs[0];
          if(!cardLogs || cardLogs.items.length == 0){
            this.database.cardTimeLogs = {
              cardId: this.card.cardId,
              items: [{
                id: this.getGUID(this.card.cardId),
                date: new Date(),
                card: this.card,
                description: descriptionToLog,
                time: timeToLog
              }]
            };
          }
          else {
              cardLogs.items.push({id: this.getGUID(this.card.cardId), date: new Date(), card: this.card, description: descriptionToLog, time: timeToLog});
              this.database.cardTimeLogs = cardLogs;
          }
        });

        this.time = "";
        this.logDescription = "";
      }

    }
