




import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  name: "Tab"
})
export default class Tab extends Vue
{
  @Prop({required: true})
  public name;

  @Prop({default: false})
  public selected;

  public isActive = false;

  constructor() {
    super();
    this.isActive = this.selected;
  }

  get href() {
    return '#' + this.name.toLowerCase().replace(/ /g, '-');
  }
}
